import { useUserContext } from 'components/User';
import { UserOrganistion } from 'types/user';

import { Selector } from '../Selector';
import { SelectorGroupedOptions, SelectorOption } from '../Selector/Selector';

const getLabel = ({ details }: UserOrganistion) => {
    if (details !== undefined) {
        if ('name' in details) {
            return details.name;
        } else if ('publishedName' in details) {
            return details.publishedName;
        }
    }

    return '';
};

const toOption = (organisation: UserOrganistion) => ({
    value: toValue(organisation),
    label: getLabel(organisation),
    type: organisation.type,
});

const toValue = ({ id, type }: UserOrganistion) => `${type}:${id}`;

const groupLabels = {
    provider: 'Providers',
    hesa: 'HESA',
    statutory_customer: 'Statutory Customers',
};

const groupLabelOrder = ['provider', 'hesa', 'statutory_customer'];

const byLabel = (a: SelectorOption, b: SelectorOption) => {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
};

const byGroupLabel = (a: SelectorGroupedOptions, b: SelectorGroupedOptions) => {
    return groupLabelOrder.indexOf(a.type) - groupLabelOrder.indexOf(b.type);
};

const groupOrganisationsByRole = (organisations: UserOrganistion[]) => {
    const grouped = organisations
        .map(toOption)
        .reduce((output: Record<string, SelectorOption[]>, organisation) => {
            return {
                ...output,
                [organisation.type]: [
                    ...(output[organisation.type]
                        ? output[organisation.type]
                        : []),
                    organisation,
                ],
            };
        }, {});

    return Object.entries(grouped)
        .map(([type, options]) => {
            return {
                type,
                groupLabel: groupLabels[type as keyof typeof groupLabels],
                options: options.sort(byLabel),
            } as SelectorGroupedOptions;
        })
        .sort(byGroupLabel);
};

export const OrganisationSelector = () => {
    const { activeOrganisation, organisations, changeOrganisation } =
        useUserContext();

    const changeOrganisationSelector = (optionValue: string) => {
        const [, id] = optionValue.split(':');
        changeOrganisation(id);
    };

    if (!organisations || !activeOrganisation) {
        return null;
    }

    const types = new Set(organisations.map(organisation => organisation.type));
    const grouped = types.size > 1;

    const options = grouped
        ? groupOrganisationsByRole(organisations)
        : organisations.map(toOption).sort(byLabel);

    return (
        <Selector
            heading={'Organisation:'}
            options={options}
            onChange={changeOrganisationSelector}
            grouped={grouped}
            value={toValue(activeOrganisation)}
        />
    );
};

OrganisationSelector.displayName = 'OrganisationSelector';
