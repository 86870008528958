import { User } from 'oidc-client-ts';
import { UserRole } from 'types/user';

export interface IdsStream {
    id: string;
    roles: UserRole[];
}

export interface IdsOrganisation {
    id: string;
    type: 'hesa' | 'provider' | 'statutory_customer';
    streams: IdsStream[];
    isPrimaryRegulator?: boolean;
}

interface EncryptedUser {
    algorithmVersion: string;
    username: string;
}

export interface DecodedJwt {
    exp: number;
    iat: number;
    organisations?: IdsOrganisation[];
    user: EncryptedUser;
}

export interface AuthUser extends User {
    decodedToken: DecodedJwt;
}

export enum TokenStatus {
    EXPIRED,
    INVALID_CLAIMS,
    NO_ORGANISATIONS,
    NO_TOKEN,
    VALID,
}
