import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { generatePath, useNavigate } from 'react-router-dom';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Button, CircularProgress } from '@mui/material';
import {
    OvtSubmissionSummary,
    SubmissionSummary,
    useUserContext,
} from 'components';
import CollectionsActivityLog from 'components/ActivityLog/CollectionsActivityLog/CollectionsActivityLog';
import { DateTime } from 'luxon';
import StepSummary from 'pages/Monitoring/StepSummary/StepSummary';
import {
    HDPAccordion,
    HDPAccordionDetails,
    HDPAccordionGroup,
    HDPAccordionSummary,
} from 'src/components/HDPAccordion';
import { PageTitle } from 'src/components/PageTitle/PageTitle';
import { ACCORDION_IDS, PATHS } from 'src/constants/constants';
import { useProviderSubmissions } from 'src/pages/Monitoring/helpers/useProviderSubmissions.helper';
import useCollection from 'src/queries/collections/useCollection';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import CollectionSummary from '../CollectionSummary/CollectionSummary';

import styles from './collectionPage.module.scss';

const formatDate = (date: string) =>
    DateTime.fromISO(date).toFormat('dd/LL/yyyy');

const Loading = () => {
    return (
        <Box className={styles.box}>
            <CircularProgress />
        </Box>
    );
};

export const CollectionPage = () => {
    const history = useNavigate();
    const { isHesa } = useUserContext();
    const { reference } = useParams();
    const { data: collection, status } = useCollection({ reference });
    const { submissions } = useProviderSubmissions({
        collectionReference: reference,
    });

    const providers = useMemo(
        () => submissions.map((s: { provider: any }) => s.provider),
        [submissions],
    );

    const goBack = () => {
        const route = generatePath(PATHS.MONITORING);
        history(route);
    };

    const renderContent = () => {
        switch (status) {
            case 'pending':
                return <Loading />;

            case 'success':
                return (
                    <React.Fragment>
                        {collection && (
                            <CollectionSummary
                                referencePeriodStart={formatDate(
                                    collection.referencePeriodStart,
                                )}
                                referencePeriodEnd={formatDate(
                                    collection.referencePeriodEnd,
                                )}
                                status={collection.state.name}
                            />
                        )}

                        {/* @ts-expect-error TODO: fix this once HDP accordion is typed */}
                        <HDPAccordionGroup>
                            {/* @ts-expect-error TODO: fix this once HDP accordion is typed */}
                            <HDPAccordion
                                size="xs"
                                searchParamId={
                                    ACCORDION_IDS.MONITORING.STEP_SUMMARY
                                }
                            >
                                <HDPAccordionSummary
                                    /* @ts-expect-error TODO: fix this once HDP accordion is typed */
                                    id="step-summary-header"
                                    aria-controls="step-summary-panel"
                                    expandIcon={<ExpandMore />}
                                    header="Step Summary"
                                    data-test-id={formatTestId(
                                        'open accordion',
                                        'step summary',
                                    )}
                                />
                                {/* @ts-expect-error TODO: fix this once HDP accordion is typed */}
                                <HDPAccordionDetails>
                                    <StepSummary
                                        collectionReference={reference!}
                                    />
                                </HDPAccordionDetails>
                            </HDPAccordion>
                            {/* @ts-expect-error TODO: fix this once HDP accordion is typed */}
                            <HDPAccordion
                                size="xs"
                                searchParamId={
                                    ACCORDION_IDS.MONITORING.SUBMISSION_SUMMARY
                                }
                            >
                                <HDPAccordionSummary
                                    /* @ts-expect-error TODO: fix this once HDP accordion is typed */

                                    id="submission-summary-header"
                                    aria-controls="submission-summary-panel"
                                    data-test-id={formatTestId(
                                        'open accordion',
                                        'submission summary',
                                    )}
                                    expandIcon={<ExpandMore />}
                                    header="Submission Summary"
                                />
                                {/* @ts-expect-error TODO: fix this once HDP accordion is typed */}
                                <HDPAccordionDetails>
                                    <SubmissionSummary
                                        collectionReference={reference}
                                    />
                                </HDPAccordionDetails>
                            </HDPAccordion>
                            {isHesa ? (
                                /* @ts-expect-error TODO: fix this once HDP accordion is typed */
                                <HDPAccordion
                                    size="xs"
                                    searchParamId={
                                        ACCORDION_IDS.MONITORING
                                            .SUBMISSION_SUMMARY_OVT
                                    }
                                >
                                    <HDPAccordionSummary
                                        /* @ts-expect-error TODO: fix this once HDP accordion is typed */
                                        id="ovt-submission-summary-header"
                                        aria-controls="ovt-submission-summary-panel"
                                        data-test-id={formatTestId(
                                            'open accordion',
                                            'submission summary ovt',
                                        )}
                                        expandIcon={<ExpandMore />}
                                        header="Submission Summary OVT"
                                    />
                                    {/* @ts-expect-error TODO: fix this once HDP accordion is typed */}
                                    <HDPAccordionDetails>
                                        <OvtSubmissionSummary
                                            collectionReference={reference}
                                        />
                                    </HDPAccordionDetails>
                                </HDPAccordion>
                            ) : (
                                <></>
                            )}
                            {isHesa && (
                                /* @ts-expect-error TODO: fix this once HDP accordion is typed */
                                <HDPAccordion
                                    size="xs"
                                    searchParamId={
                                        ACCORDION_IDS.MONITORING.ACTIVITY_LOG
                                    }
                                >
                                    <HDPAccordionSummary
                                        /* @ts-expect-error TODO: fix this once HDP accordion is typed */
                                        id="activity-log-header"
                                        aria-controls="activity-log-panel"
                                        data-test-id={formatTestId(
                                            'open accordion',
                                            'activity log',
                                        )}
                                        expandIcon={<ExpandMore />}
                                        header="Activity log"
                                    />
                                    {/* @ts-expect-error TODO: fix this once HDP accordion is typed */}
                                    <HDPAccordionDetails>
                                        <CollectionsActivityLog
                                            providers={providers}
                                        />
                                    </HDPAccordionDetails>
                                </HDPAccordion>
                            )}
                        </HDPAccordionGroup>
                        <div className={styles.buttons}>
                            <Button
                                data-test-id="back"
                                disabled={false}
                                disableRipple={true}
                                variant="contained"
                                onClick={goBack}
                            >
                                Back
                            </Button>
                        </div>
                    </React.Fragment>
                );

            default:
                return <></>;
        }
    };

    return (
        <>
            <PageTitle
                title={collection ? `${collection.name}` : `Monitoring`}
            />
            {renderContent()}
        </>
    );
};

export default CollectionPage;
