import { ReactNode } from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { useUserContext } from 'components';
import useProviders from 'queries/provider/useProviders';
import useRegulators from 'queries/regulator/useRegulators';
import { TokenStatus } from 'types/auth';

const Error = ({ title, children }: { title: string; children: ReactNode }) => (
    <Grid container>
        <Grid item xs={12} sm={6} md={4}>
            <Box bgcolor={'grey.100'} p={3}>
                <Typography paragraph>
                    <strong>{title}</strong>
                </Typography>
                {children}
            </Box>
        </Grid>
    </Grid>
);

const AccessDeniedError = ({ onLogin }: { onLogin: () => void }) => {
    return (
        <Error title={'Access Denied'}>
            <Typography paragraph>
                You do not have a valid IDS role and/or organisation to access
                HDP.
            </Typography>
            <Typography paragraph variant={'body2'}>
                You may be trying to log in as a provider that is not one of the
                8 default providers currently supported by HDP.
            </Typography>
            <Link component={'button'} onClick={onLogin} variant={'body1'}>
                Return to IDS login
            </Link>
        </Error>
    );
};

const NoMatchingProvidersError = ({ onLogin }: { onLogin: () => void }) => {
    return (
        <Error title={'No matching providers'}>
            <Typography paragraph>
                None of the provider organisations your user has access to exist
                in the current environment.
            </Typography>
            <Link component={'button'} onClick={onLogin} variant={'body1'}>
                Return to IDS login
            </Link>
        </Error>
    );
};

const NoDataError = () => {
    return (
        <Error title={'Failed to retrieve application data'}>
            <Typography paragraph>
                The application failed to load because it was not able to
                retrieve all the data it needs in order to run.
            </Typography>
            <Typography paragraph>Try refreshing your browser</Typography>
        </Error>
    );
};

const UnknownError = ({ onLogin }: { onLogin: () => void }) => {
    return (
        <Error title={'Unknown Error'}>
            <Typography paragraph>
                An unknown error has prevented the HDP application from loading.
            </Typography>
            <Typography paragraph variant={'body2'}>
                Try using the link below to return to IDS and log in again.
            </Typography>
            <Link component={'button'} onClick={onLogin} variant={'body1'}>
                Return to IDS login
            </Link>
        </Error>
    );
};

export const AppError = ({ tokenStatus }: { tokenStatus: TokenStatus }) => {
    const { signOut, activeOrganisation } = useUserContext();
    const { isError: isRegulatorError } = useRegulators();
    const { isError: isProviderError } = useProviders();

    const handleLogin = () => signOut();

    if (
        activeOrganisation?.type === 'provider' &&
        !activeOrganisation.details?.onboarded
    ) {
        return <NoMatchingProvidersError onLogin={handleLogin} />;
    }

    if (isRegulatorError || isProviderError) {
        return <NoDataError />;
    }

    switch (tokenStatus) {
        case TokenStatus.NO_ORGANISATIONS:
            return <AccessDeniedError onLogin={handleLogin} />;

        default:
            return <UnknownError onLogin={handleLogin} />;
    }
};

AppError.displayName = 'AppError';
